@import 'account-flagship_tasting_room.scss';

.b-track_notification {
	&-inner {
		border-color: $color-barefoot-purple;
		background-color: $color-message-background;
	}
	&-link {
		color: $color-link;
	}
}

.b-tab_list-tab {
	&.m-active {
		border-bottom: 3px solid $color-pink-accent;
	}
}
